
import { Title, Container, Text } from './About.styles';

const AboutPage = () => {
  return (
    <Container>
      <Title><h1>Sobre a Garagem 57</h1></Title>
      <Text>
        A Garagem 57 é uma loja especializada em motocicletas de altas e baixas cilindradas,
        oferecendo uma ampla seleção de veículos novos e seminovos. Aqui você encontra
        motos de diversas marcas e estilos, perfeitas para atender tanto iniciantes quanto
        os mais experientes apaixonados por duas rodas.
      </Text>
      <Text>
        Além das motos, temos uma variedade de acessórios e produtos para motociclistas, incluindo capacetes,
        jaquetas, óleos lubrificantes, camisas, luvas e pneus. Tudo o que você precisa para garantir conforto,
        segurança e performance em sua moto.
      </Text>
      <Text>
        Nossa loja conta com uma área de convivência onde os clientes podem relaxar e aproveitar um café ou bebidas
        como refrigerantes e sucos, além de opções de lanches como salgados e sanduíches naturais. Para momentos de
        descontração, oferecemos também uma mesa de sinuca.
      </Text>
      <Text>
        Outro diferencial é nosso serviço de lava-jato e mecânica. Enquanto você cuida da sua moto, nós cuidamos dela
        com serviços de qualidade em um ambiente climatizado e confortável. Nossa localização é privilegiada, garantindo
        fácil acesso para nossos clientes.
      </Text>
      <Text>
        Todas as quintas-feiras, das 19h às 23h, promovemos o evento <strong>Encontro da Garagem 57</strong>. Esse é um
        momento especial para reunir amigos e famílias em um ambiente tranquilo e amistoso. Durante o encontro, você pode
        conferir nossas motos e aproveitar a noite com uma programação leve e descontraída.
      </Text>
      <Text>
        Venha nos visitar e fazer parte da comunidade Garagem 57!
      </Text>
    </Container>
  );
};

export default AboutPage;