import useSideBar from '../../../shared/Hooks/useSiderBar';
import Submenu from './SubMenu/SubMenu';

import { FaBars } from "react-icons/fa";
import { RxDoubleArrowUp } from "react-icons/rx";

import { 
  NavIconOpen, 
  SidebarNav, 
  SideBarWrap, 
  CSidebarHeader, 
  CSidebar, 
  CaseWindow,
  Overlay,
  CClosed,
 } from './Sidebar.styles';

import LogoBranca from '../../../assets/img/logo/logoBranca.png';

const Sidebar = () => {
  const { sideBarOpen, toggleSideBar} = useSideBar();

  return (
    <>
    <CSidebar>
      <h1>
        <a href="/">
        <img
          src={LogoBranca}
          alt='Logo Garagem 57'
        />
        </a>
      </h1>
      <NavIconOpen to="#">
        <FaBars onClick={toggleSideBar} />
      </NavIconOpen>
      
      <CaseWindow $toggleSideBar={sideBarOpen}>
      <Overlay $toggleSideBar={sideBarOpen} />
        <SidebarNav $toggleSideBar={sideBarOpen}>
          <CSidebarHeader>
            <div>
              <h2>
              <a href="/">
                <img
                  src={LogoBranca}
                  alt='Logo Garagem 57'
                />
              </a>
              </h2>
            </div>
          </CSidebarHeader>
          <SideBarWrap>
            <Submenu />
          </SideBarWrap>
          <CClosed onClick={toggleSideBar}>
            <RxDoubleArrowUp />
          </CClosed>
        </SidebarNav>
      </CaseWindow>
    </CSidebar>
  </>
  )
}

export default Sidebar;