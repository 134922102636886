import { SLocation, CMaps, CAddress, CTitle, CContact, ScheduleTable } from './Location.styles';

import { MdOutlineLocationOn } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";

import { useModalWhatsApp } from '../../../../shared/Context/ModalWhatsApp';

const Location = () => {
  const { showModal } = useModalWhatsApp();

  return (
    <SLocation>
      <CMaps>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.0946944371826!2d-35.721320624973075!3d-9.672949990416324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x701453eab74e5a5%3A0x33c1f54279cd5094!2sGaragem57!5e0!3m2!1spt-BR!2sbr!4v1726548260435!5m2!1spt-BR!2sbr" 
        
        loading="lazy">
        </iframe>
      </CMaps>
      <CAddress>
        <CTitle>
          <h1>Garagem 57</h1>
          <span>A casa do motociclista</span>
        </CTitle>
        <CContact>
          <address>
            <a href="https://maps.app.goo.gl/sBB8FZu5VD9sCVxK7" target='_blank'>
            <MdOutlineLocationOn size={20}/>
             R. Epaminondas Gracindo, 57 - Pajuçara, Maceió - AL, 57022-183
            </a>
          </address>
          <span>
            <a href="tel:+5582999994660">
              <BsTelephoneFill />
              (82) 9 9999-4660
            </a>
          </span>
          <button onClick={showModal}>
            WhatsApp
            <BsWhatsapp size={20}/>
          </button>
        </CContact>
        <ScheduleTable >
          <h2>Horários de funcionamento:</h2>
          <p>Segunda-feira: <span>09:00 ás 18:00</span> </p>
          <p>Terça-feira: <span>09:00 ás 18:00</span> </p>
          <p>Quarta-feira: <span>09:00 ás 18:00</span> </p>
          <p>Quinta-feira: <span>09:00 ás 23:00</span> </p>
          <p>Sexta-feira: <span>09:00 ás 18:00</span> </p>
          <p>Sábado: <span>09:00 ás 13:00</span> </p>
        </ScheduleTable >
      </CAddress>
    </SLocation>
  )
}

export default Location;