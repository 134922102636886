import styled from 'styled-components';

// Contêiner principal que agrupa o visualizador
export const ContainerImgViewer = styled.div`
  display: grid;
  grid-template-columns: 90% 10%; /* Usar fr para tornar as colunas responsivas */
  gap: 1rem; /* Adicionar espaço entre as colunas */
  padding: 1rem; /* Adicionar padding */
`;

// Contêiner para a imagem principal
export const MainImageContainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30rem;

  img {
    width: 90%;
    height: 30rem; /* Manter a proporção da imagem */
    object-fit: cover;
  }
`;

export const ContainerThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Estilo para os botões
export const ArrowButtonUp = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: gray; 
  }
`;

export const ArrowButtonDown = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: gray; 
  }
`;

// Contêiner para as miniaturas
export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: flex-start; /* Garantir que as imagens fiquem no topo */
  overflow-y: hidden; /* Permitir rolagem vertical */
  overflow-x: hidden; 
  max-height: 60vh; /* Manter a altura máxima */
  transition: transform 0.3s ease;
  padding: 0.5rem; /* Adicionar padding para ajustar espaçamento interno */
`;

// Estilo para as miniaturas
export const Thumbnail = styled.img<{$isActive: boolean}>`
  width: 100%; /* Usar 100% para que a imagem se ajuste ao contêiner */
  max-width: 4rem; /* Definir largura máxima */
  border-radius: 5px;
  height: auto;
  cursor: pointer;
  transition: border 0.3s, transform 0.2s;
  opacity: ${(props) => (props.$isActive ? 1 : 0.6)};
  border: ${(props) => (props.$isActive ? '2px solid black' : '2px solid transparent')};

  &:hover {
    transform: scale(1.03); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;
