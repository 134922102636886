import styled from 'styled-components';

export const CStoreNews = styled.section`
  padding: 2rem 6rem;
  

  @media screen and (max-width: 1024px) {
      padding: 1rem;
  }
`

export const CNewsMotorCicles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;

  .container-grid {
    display: flex;
    margin-bottom: 1rem;
  }


  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

export const CItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ButtonStock = styled.button`
    margin-top: 0.9rem;
    padding:  0.6rem 4rem;
    color: #fff;
    border: none;
    background-color: transparent;
    background-color: #D22219;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-family: var(--font-Roboto);
    font-weight: bold;
    font-size: 1.1em;
    cursor: pointer;
    transition: ease 0.9s;

    &:hover {
      background-color: #433FBF;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
`

export const CHeaderStoreNews = styled.div`
  color: #949494;
  font-family: var(--font-Roboto);
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid #c1c1c1;

  h2 {
    margin-bottom: 1rem;
    color: #949494;
  }
`