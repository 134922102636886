import { Link, useLocation } from 'react-router-dom';
import { CHeader, ButtonHeader } from './Navbar.styles';
import { BsWhatsapp } from "react-icons/bs";
import LogoBranca from '../../../assets/img/logo/logoBranca.png';

import { useModalWhatsApp } from '../../../shared/Context/ModalWhatsApp';

const NavBar = () => {

  const { showModal } = useModalWhatsApp();
  const location = useLocation();
  
  function scrollToSection(sectionId: string) {
    if (sectionId === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      };
    };
  };


  return (
    <CHeader>
      <h1>
        <a href="/">
          <img src={LogoBranca} alt='Logo Garagem 57' />
        </a>
      </h1>
      <nav>
        <ul>
          <li>
            <Link to='/'>Início</Link>
          </li>
          <li>
            <Link to='/StockMotorcycle'>Estoque</Link>
          </li>
          <li>
            <Link to="/About">
              Sobre
            </Link>
          </li>
          <li>
            <Link to='/Dating'>Encontros</Link>
          </li>
          <li>
            {location.pathname === '/' &&
              <Link to='#' onClick={() => scrollToSection('services')}>
                Serviços
              </Link>
            }
          </li>
          <ButtonHeader type="button" onClick={showModal}>
            Fale conosco
            <BsWhatsapp size={28} />
          </ButtonHeader>
        </ul>
      </nav>
    </CHeader>
  );
};

export default NavBar;
