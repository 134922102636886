import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 1rem 6rem;
  width: 100%;
  height: auto;
  font-family: var(--font-Roboto);
  height: 100vh;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 0 0.5rem;
  }
`

export const Title = styled.div`
  font-size: 2.5em;
  color: #949494;
  margin-bottom: 20px;
  font-weight: bold;
  border-bottom: 0.1rem solid #c1c1c1;

  h2 {
    margin:0 1rem 1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.7em;

    h2 {
      margin: 1rem 0;
    }
  }

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerFinanceForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  

  label {
    font-weight: bold;
    padding: 0.5rem;
    color: #949494;
  }

  input {
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    width: 22rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: 0.01rem solid #c1c1c1;

    &:focus {
      outline: none;
      border: 0.1rem solid #c1c1c1;
    }
  }
`

export const FormTelephone = styled.div`
  display: flex;
  flex-direction: column;
`
export const Telephone = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: 0.01rem solid #c1c1c1;

    &:focus {
      outline: none;
      border: 0.1rem solid #c1c1c1;
    }
  }
`

export const CheckboxConfirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  input {
    width: 1rem;
  }
`

export const ButtonForm = styled.button`
  margin-top: 1rem;
  width: 100%;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  background-color: #433FBF;
  transition: background-color ease 0.3s;
  color: #fff;

  &:hover {
    background-color: #D22219;
  }

  &:active {
    background-color: #D22219;
  }
`

export const ContactDirect = styled.div`
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);

  h3 {
    font-weight: bold;
  }
`

