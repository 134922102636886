import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: auto;
  height: 100%;
  font-family: var(--font-Roboto);
`;

export const Title = styled.div`
  font-size: 2.5em;
  color: #949494;
  margin-bottom: 20px;
  font-weight: bold;
  border-bottom: 0.1rem solid #c1c1c1;

  h2 {
    margin:0 1rem 1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.7em;
    text-align: center;

    h2 {
      margin:1rem 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 75rem;

  line-height: 1.6;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const VideoWrapper = styled.div`
  flex: 1;

  video {
    width: 100%;
    max-width: 35rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    video {
      width: 100%;
      border-radius: none;
    }
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  margin-left: 2rem;

  p {
    font-size: 1.2rem;
    color: #555;
  }

  @media (max-width: 768px) {
    margin: 0;
    p {
      text-align: center;
    }
  }
`;


