import { CFooter } from './Footer.styles';

const Footer = () => {
  return (
    <CFooter>
      <p>&copy; AVA - VEÍCULOS | Todos os direitos reservado.</p>
      <div>
        <a href="#" >
          Termos
        </a>
        <a href="#" >
          Privacidade
        </a>
      </div>
    </CFooter>
  )
}

export default Footer;