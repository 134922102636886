import styled from 'styled-components';

export const CPagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem ;
  width: 100%;
  font-family: var(--font-Roboto);
  
`

export const CControlPagination = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`

export const ButtonNext = styled.button`
  background-color: transparent;
  border: none;
  color: #D22219;

  svg {
    font-size: 1.4em;
  }
`

export const ButtonPrev = styled.button`
  background-color: transparent;
  border: none;
  color: #D22219;

  svg {
    font-size: 1.4em;
  }
`

interface ButtonPageProps {
  isActive: Boolean;
}

export const ButtonPage = styled.button<ButtonPageProps>`
  background-color: transparent;
  border: none;
  border-radius: 2rem;
  margin: 0.3rem;
  width: 100%;

  color: ${({ isActive }) => (isActive ? '#fff' : '#D22219')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  background-color: ${({ isActive }) => (isActive ? '#433FBF' : 'transparent')}; 
  font-size: ${({ isActive }) => (isActive ? '1.4em' : '1.1em')};
`