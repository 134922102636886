import { Carousel } from 'react-bootstrap';
import { CSlides } from './Slides.styles';

import financeImage from '../../../../assets/img/mainSlides/financiamento.png';



const Slides = () => {
  return (
      <CSlides>
        <div className='container-carousel'>
          <Carousel>
            <Carousel.Item>
              <img
                className='d-block w-100'
                src={financeImage}
                alt='Faça seu financiamento com a garagem 57'
                loading='lazy'
              />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </CSlides>
  );
};

export default Slides;