import styled from 'styled-components';


export const ContainerFinanceSale = styled.div`
  background-color: #fff;
  font-family: var(--font-Roboto);
  padding: 2rem 6rem;
  display: flex;
  
  h2 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  @media screen and (max-width: 1200px) {
    padding: 0;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 0;
  }
`

export const COneCase = styled.div`
  display: flex;
  width: 100%;
  background-color: #D22219;
  border-radius: 1.2rem 0 0 1.2rem  ;
  gap: 2rem;

  @media screen and (max-width: 1200px) {
    border-radius: 0;
    justify-content: flex-end;
  }
`

export const CTwoCase = styled.div`
  display: flex;
  width: 100%;
  background-color: #433FBF;
  border-radius: 0 1.2rem 1.2rem 0;
  gap: 2rem;

  @media screen and (max-width: 1200px) {
    border-radius: 0;
    justify-content: flex-end;
  }
`

export const CFinance = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    width: auto;
    height: 22rem;
    object-fit: cover;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
    max-width: 100%;
  }

  @media screen and (max-width: 1200px) {
    position: relative;
    z-index: 2;  
    

    img {
      height: 16rem;
    }
  }
`
export const CFinanceContent = styled.div`
  width: 100%;
  margin: 1rem;
  color: #fff;

  h2 {
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  button {
    margin-top: 2rem;
    border-radius: 1rem;
    padding: 0.7rem 1.1rem;
    border: none;
    color: #fff;
    background-color: #433FBF;
  }

  @media screen and (max-width: 1200px) {
    position: absolute;
    z-index: 4;
    padding: 1rem;
    margin: 0;
  }
`
export const CSale = styled.div`
  display: flex;

  img {
    width: auto;
    height: 22rem;
    
    border-radius: 0 1.2rem 1.2rem 0;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
    opacity: 0.9;
    object-fit: cover;
  }

  @media screen and (max-width: 1200px) {
    position: relative;
    z-index: 2;  
    
    img {
      height: 16rem;
      border-radius: 0;
    }
  }
`

export const CSaleContent = styled.div`
  flex-direction: column;
  width: 100%;
  color: #fff;  
  margin: 1rem;
  
  h3 {
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  button {
    margin-top: 2rem;
    border-radius: 1rem;
    padding: 0.7rem 1.1rem;
    background-color: #D22219;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    position: absolute;
    z-index: 4;
    padding: 1rem;
    margin: 0;
  }
`