import { ContentSteps, StepItem, StepText, StepTitle, StepsContainer } from './StepsComponent.styles';

import { FaCalculator, FaFileAlt, FaPenNib, FaMotorcycle  } from 'react-icons/fa';


const StepsComponent = () => {
  return (
    <StepsContainer>
      <ContentSteps>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaCalculator/>
              <strong>Simulação de Financiamento</strong>
            </StepTitle>
            <p>
              O cliente escolhe o veículo desejado e realiza a simulação do financiamento, 
              informando o valor de entrada, CPF, data de nascimento e telefone. Esses dados
              são necessários para calcular o valor das mensalidades e apresentar as condições
              de pagamento de forma precisa.
            </p>
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaFileAlt/>
              <strong>Análise de Crédito</strong>
            </StepTitle>
            <p>
            Após a simulação, se o cliente for pré-aprovado, será necessário enviar a documentação, 
            como CNH ou RG e comprovante de residência. Esses documentos são essenciais para dar continuidade 
            ao processo e concluir a análise de crédito.
            </p>  
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaPenNib/>
              <strong>Aprovação e Assinatura do Contrato</strong>
            </StepTitle>
          <p>
            Após o envio da documentação e a aprovação do crédito, o cliente deve proceder com a
            assinatura do contrato, que pode ser feita digitalmente ou presencialmente, facilitando
            o andamento do processo.
          </p>
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
          <StepTitle>
            <FaMotorcycle />
            <strong>Entrega do Veículo</strong>
          </StepTitle>
          <p>
            Com o contrato assinado, a loja realiza os trâmites finais e a moto é entregue ao 
            cliente. Ele já pode sair pilotando a moto nova no mesmo dia, agilizando todo o processo.
          </p>
          </StepText>
        </StepItem>
      </ContentSteps>
    </StepsContainer>
  );
};

export default StepsComponent;