import { useState } from 'react';
import { ButtonNext, ButtonPage, ButtonPrev, CControlPagination, CPagination } from './Pagination.styles';
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";

interface ItemProps {
  id: string; // Mudei para string
  title: string; // Alterado para title
  mileage?: number; // Você pode adicionar outras propriedades, se necessário
  year?: number;
  price?: number;
  images?: { image_url: string }[];
}

interface PaginationProps {
  data: ItemProps[];
  itemsPerPage: number;
  currentPage: number; // Para controle de página
  onPageChange: (page: number) => void; // Função para mudar a página
}

const Pagination = ({ data, itemsPerPage, currentPage, onPageChange }: PaginationProps) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1); // Chama a função para mudar a página
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1); // Chama a função para mudar a página
    }
  };

  return (
    <CPagination>
      <ul>
        {currentItems.map(item => (
          <li key={item.id}></li>
        ))}
      </ul>
      <CControlPagination>
        <ButtonPrev onClick={prevPage} disabled={currentPage === 1}>
          <MdOutlineKeyboardDoubleArrowLeft />
        </ButtonPrev>

        {[...Array(totalPages)].map((_, i) => (
          <ButtonPage 
          key={i} 
          onClick={() => onPageChange(i + 1)} // Chama a função para mudar a página
          isActive={currentPage === i + 1}
          >
            {i + 1}
          </ButtonPage>
        ))}

        <ButtonNext onClick={nextPage} disabled={currentPage === totalPages}>
          <MdOutlineKeyboardDoubleArrowRight />
        </ButtonNext>
      </CControlPagination>
    </CPagination>
  )
}

export default Pagination;
