import styled from 'styled-components';

export const CSSearch = styled.section`
  width: 100%;
  height: auto;
  font-family: var(--font-Roboto);
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

export const CallSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D22219;
  padding: 1rem 16rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  width: 100%;

  @media screen and (max-width: 1200px) {
   gap: 1rem;
   padding: 1rem;
   justify-content: center;
  }
`

export const ButtonStockSearch = styled.button`
  display: none;
  
  @media screen and (max-width: 1200px) {
   display: block;
   background-color: transparent;
   padding: 0.7rem;
   background-color: #433FBF;
   color: #fff;
   font-weight: bold;
   border: none;
   transition: ease 0.3s;

   &:active {
    background-color: #D22219;
   }
  }
`

export const CTitle = styled.div`
  text-align: center;
  
 
  h2 {
    font-size:1.5em;
    font-weight: bold;
  }

  p {
    margin-top: 0.4rem;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
  }

  span {
    font-weight: bold;
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
   display: none;
  }
`
export const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  

  div {
    display: flex;
  }

  span {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  input {
    padding: 0.5rem 1rem;
    border: solid 1px #c1c1c1;
    border-radius: 5px 0 0 5px;
    outline: none;
  }

  button {
    background-color: #433FBF;
    border: none;
    border-radius: 0 5px 5px 0;
    
    svg {
      color: #fff;
    }
  }
`