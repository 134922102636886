import { ContentSteps, StepItem, StepText, StepTitle, StepsContainer } from './StepsComponentSale.styles';
import { FaCar, FaFileAlt, FaPenNib, FaHandshake } from 'react-icons/fa';
import { FaMotorcycle } from "react-icons/fa6";

const StepsComponentSale = () => {
  return (
    <StepsContainer>
      <ContentSteps>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaMotorcycle />
              <strong>Avaliação do Veículo</strong>
            </StepTitle>
            <p>
              O cliente traz o veículo para avaliação, onde os especialistas realizam uma inspeção minuciosa, 
              verificando as condições mecânicas, estéticas e documentais para determinar o valor de mercado.
            </p>
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaFileAlt />
              <strong>Documentação e Registro</strong>
            </StepTitle>
            <p>
              Após a avaliação, o cliente deve fornecer a documentação necessária, como o CRV (Certificado de Registro de Veículo), 
              laudos de vistoria e outros documentos que comprovem a propriedade e condição do veículo.
            </p>
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaPenNib />
              <strong>Definição de Preço e Contrato</strong>
            </StepTitle>
            <p>
              Com a avaliação completa e a documentação em ordem, o cliente e a loja definem o preço de consignação. 
              Um contrato é elaborado, detalhando as condições de venda e a comissão da loja.
            </p>
          </StepText>
        </StepItem>
        <StepItem>
          <StepText>
            <StepTitle>
              <FaHandshake />
              <strong>Exposição e Venda</strong>
            </StepTitle>
            <p>
              O veículo é colocado à venda, exposto na loja e/ou em plataformas online. A loja se encarrega de todo o 
              processo de venda, mantendo o cliente informado sobre o andamento e as propostas recebidas.
            </p>
          </StepText>
        </StepItem>
      </ContentSteps>
    </StepsContainer>
  );
};

export default StepsComponentSale;
