import styled from 'styled-components';

export const ErroFormFinance = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: flex-end;
  color: #D22219;
  margin-top: 0.3rem;
  font-size: 0.8em;

  svg {
    font-size: 1.1em;
  }

`