import { useContext } from "react"
import { SidebarContext } from '../Context/SideBarContext';

const useSideBar = () => {
  const contextSideBar = useContext(SidebarContext);

  if(!contextSideBar) {
    throw new Error  ("useSideBar must be used within a SideBarProvider");
  }
  return contextSideBar;
};

export default useSideBar;