import { SlSpeedometer } from 'react-icons/sl';
import { 
  CardSmallContainer, 
  CardImage, 
  CardBody,  
  CardTitle, 
  CardText, 
  CardButton, 
  CardPrice 
} from './styles/SmallCards.styles';
import { SmallCardRequest } from './@types/SmallCards';
import { IoCalendarOutline } from "react-icons/io5";


const SmallCard = ({image, title, year, km, price, onDetailsClick}: SmallCardRequest) => {
  return (
    <CardSmallContainer>
       <CardImage>
        <img src={image} alt={title} loading='lazy' />
      </CardImage>
      <CardBody>
        <CardTitle>
          {title}
        </CardTitle>
        <CardText>
          <p><SlSpeedometer/> {km} km</p>
        </CardText>
        <CardPrice><p>{price}</p> <span><IoCalendarOutline /> {year}</span></CardPrice>
        <CardButton onClick={onDetailsClick}>Ver detalhes</CardButton>
      </CardBody>
    </CardSmallContainer>
  )
}

export default SmallCard;