import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';

// ----------------------- > Container < -----------------------blue
export const ContainerServiceContent = styled.section`
  display: flex;
  justify-content: space-between;
  font-family: var(--font-Roboto);
  border-radius: 0 1.2rem 1.2rem;
  padding: 1rem 0.3rem;
  gap: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    padding:0;
    flex-direction: column;
    gap: 0;
  }
`;
// ----------------------- > Container Content Service < -----------------------yellow
export const ContentServicesOne = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 15rem;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0;

    img {
      width: 5rem;
    }
  }
`;
// -----------> header < ------------------
export const HeaderContentServiceOne = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 4rem;
      font-weight: bold;
      max-width: 90%;
      color: #00ff00;
    }

    span {
      margin-top: 0.3rem;
      font-size: 1.05rem;
      word-break: break-word;
      white-space: normal;
      max-width: 88%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    div {
      h3 {
        font-size: 1.5rem;
      }

      span {
        font-size: 0.9rem;
      }
    }
  }
`;

// ----------------------- > Container Service and Contact < ----------------------- green
export const ContentServiceAndContact = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  gap: 3rem;

  ul {
    list-style: disc;
    margin: 0;
  }

  li {
    padding: 0.3rem 0.2rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;

    &::marker {
      color: #00ff00;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    gap: 0;

    align-items: flex-start;

    ul {
      display: none;
    }
  }
`;

// ----------------------- > Container Contact < -----------------------
export const ContentContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.3rem;
  padding: 0.5rem;
  margin-left: 2rem;

  p {
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: 0.8rem;
  }

  a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.3em;
    margin-bottom: 0.3rem;

    svg {
      font-size: 0.9em;
      color: #00ff00;
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: #00ff00;
    }
  }

  div {
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        font-size: 2rem;
        color: #00ff00;
        transition: ease 0.3s;
      }

      &:hover {
        svg {
          color: #000;
        }
      }
    }
  }

  .agendamento-button {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    background-color: #00ff00;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color ease 0.3s;

    &:hover {
      background-color: #000;
      color: #00ff00;
    }

    &:active {
      background-color: #000;
      color: #00ff00;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin: 0;
    flex-direction: center;
    align-items: center;
    width: 100%;

    .agendamento-button {
      border-radius: 0;
    }
  }
`;


// --Windows Tab Configurations Styles
export const TabContainer = styled.div`
  padding: 2rem 6rem;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0rem;
  }
`;

export const TabHeader = styled.div`
  display: flex;
  

  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    
  }
`;

export const TabButton = styled.button<{ active: boolean; tabIndex: number }>`
  border-radius: 0.3rem 0.3rem 0 0;
  padding: 0.5rem 3rem;
  font-family: var(--font-Roboto);
  font-size: 1.6em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  transition: background-color 0.3s, opacity 0.3s;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);


  background-color: ${({ active, tabIndex }) => {
    if (active) {
      return tabIndex === 0 ? '#F1F1F1' : '#F1F1F1';
    } else {
      return tabIndex === 0 ? '#F1F1F1' : '#F1F1F1';
    }
  }};

  color: #949494;


  &:hover {
    background-color: ${({ active, tabIndex }) => {
      if (active) {
        return tabIndex === 0 ? '#e9e9e9' : '#e9e9e9';
      } else {
        return tabIndex === 0 ? '#e9e9e9' : '#e9e9e9';
      }
    }};
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.5rem 2rem;
  }
`;

// Novo estilo apenas para o conteúdo, com transição aplicada
export const TabContentInner = styled.div`
  background-color: #f1f1f1;
  border-radius: 0 1.2rem 1.2rem;
`;

// TabContent mantém o estilo básico sem a animação
export const TabContent = styled.div<{ isActive: boolean }>`
  background-color: #f1f1f1;
  border-radius: 0 1.2rem 1.2rem;

  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};

  &.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`;

export const ContentServicesTwo = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 15rem;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0;

    img {
      width: 5rem;
    }
  }
`

// ----------------------- > Carousel Configuration < -----------------------
export const CustomCarousel = styled(Carousel)`
  background-color: #f8f9fa;
  width: 23rem;
  height: 30rem;
  margin-left: 1rem;
  border-radius: 1rem;

  .carousel-inner {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .carousel-item {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  .carousel-indicators li {
    background-color: #00ff00;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 15rem;
    border-radius: 0;

    .carousel-inner {
      border-radius: 0;
    }

    .carousel-item {
      border-radius: 0;
    }

    .carousel-item img {
      border-radius: 0;
    }
  }
`;

export const HeaderService = styled.div`
  color: #949494;
  font-family: var(--font-Roboto);
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 2rem;

  border-bottom: 0.1rem solid #c1c1c1;

  h2 {
    margin-bottom: 1rem;
    color: #949494;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    width: 96%;
    text-align: center;
    font-size: 1.9em;
 
  }
`

export const ContentServiceAndContactTwo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  gap: 3rem;

  ul {
    list-style: disc;
    margin: 0;
  }

  li {
    padding: 0.3rem 0.2rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;

    &::marker {
      color: #A69258;
      font-size: 1.4rem;
    }
  }


  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    gap: 0;

    align-items: flex-start;

    ul {
      display: none;
    }
  }
`

export const HeaderContentServiceTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 4rem;
      font-weight: bold;
      color: #A69258;
    }

    span {
      margin-top: 0.3rem;
      font-size: 1.05rem;
      word-break: break-word;
      white-space: normal;
      max-width: 88%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    div {
      h3 {
        font-size: 1.5rem;
      }

      span {
        font-size: 0.9rem;
      }
    }
  }
`

export const ContentContactTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.3rem;
  padding: 0.5rem;
  margin-left: 2rem;

  p {
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: 0.8rem;
  }

  a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.3em;
    margin-bottom: 0.3rem;

    svg {
      font-size: 0.9em;
      color: #A69258;
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: #A69258;
    }
  }

  div {
    display: flex;
    gap: 1rem;

    button {
      width: 100%;
      padding: 0.5rem 1rem;
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        font-size: 2rem;
        color: #A69258;
        transition: ease 0.3s;
      }

      &:hover {
        svg {
          color: #595336;
        }
      }
    }
  }

  .agendamento-button {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    background-color: #A69258;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color ease 0.3s;
    color: #fff;

    &:hover {
      background-color: #595336;
      color: #fff;
    }

    &:active {
      background-color: #595336;
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin: 0;
    flex-direction: center;
    align-items: center;
    width: 100%;

    .agendamento-button {
      border-radius: 0;
    }
  }
`