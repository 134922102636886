import styled from 'styled-components';


export const CSlides = styled.section`
  width: 100%;
  height: auto;

  h3{
    font-weight: bold;
    font-size: 24px;
  }

  img {
    max-height: 550px;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    img {
      height: 210px;
      max-height: 510px;
      object-fit: cover;
    } 
  }
`

export const ButtonCaption = styled.button`
  border-radius: 5px;
  border: none;
  background-color: red;
  color: white;
  font-size: 16px;
  font-family: var(--font-Roboto);
  padding: 0.5rem 1rem;
`