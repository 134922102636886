import { useState, useRef } from 'react';
import { Image } from './@types/imagesViewer';
import { 
  ContainerThumbnail, 
  Thumbnail, 
  ThumbnailContainer, 
  ContainerImgViewer, 
  ArrowButtonUp,
  ArrowButtonDown, 
  MainImageContainer,
} from './imageViewer.styles';

import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";

interface ImageViewerRequest {
  images: Image[];
}

const ImageViewer = ({ images }: ImageViewerRequest) => {
  const [mainImage, setMainImage] = useState(images.find(img => img.isMain) || images[0]);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const handleImageLoad = (url: string) => {
    setLoadedImages((prev) => [...prev, url]);
  };

  const scroll = (direction: 'up' | 'down') => {
    if (thumbnailContainerRef.current) {
      const scrollAmount = 100; // Reduzido para facilitar o scroll em telas menores
      const newScrollTop = direction === 'up' 
        ? Math.max(thumbnailContainerRef.current.scrollTop - scrollAmount, 0)
        : Math.min(thumbnailContainerRef.current.scrollTop + scrollAmount, thumbnailContainerRef.current.scrollHeight);
      thumbnailContainerRef.current.scrollTo({ top: newScrollTop, behavior: 'smooth' });
    }
  };

  return (
    <ContainerImgViewer>
      <MainImageContainer>
        <img src={mainImage.url} alt="Imagem principal" loading='lazy'/>
      </MainImageContainer>
      <ContainerThumbnail>
        <ArrowButtonUp onClick={() => scroll('up')} disabled={false}>
          <BsArrowUpCircleFill size={32} />
        </ArrowButtonUp>
        <ThumbnailContainer ref={thumbnailContainerRef}>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              src={image.url}
              alt={`Imagem ${index + 1}`}
              $isActive={image.url === mainImage.url}
              onClick={() => setMainImage(image)}
              loading='lazy'
            />
          ))}
        </ThumbnailContainer>
        <ArrowButtonDown onClick={() => scroll('down')} disabled={false}>
          <BsArrowDownCircleFill size={32} />
        </ArrowButtonDown>
      </ContainerThumbnail>
    </ContainerImgViewer>
  );
}

export default ImageViewer;