import styled from 'styled-components';

export const ContainerStock = styled.div`
  width: 100%;
  
`

export const CItemsStock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 6rem; /* Corrigido com dois pontos */
`

export const CContent = styled.div`
  display: flex;
  
`

export const CCardsStock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: (3, auto);
  row-gap: 1.2rem; /* Espaçamento vertical entre as linhas */
  column-gap: 1rem; /* Espaçamento horizontal entre as colunas */

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* Reduz para 3 colunas em telas menores */
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* Reduz para 2 colunas */
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Para telas muito pequenas, exibe 1 item por linha */
  }
`;
