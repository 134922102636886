import { ReactNode, createContext, useState } from "react";

export interface SideBarContextProps {
  sideBarOpen: boolean;
  toggleSideBar: () => void;
}

interface SideBarProviderProps {
  children: ReactNode;
}

export const SidebarContext = createContext<SideBarContextProps | undefined>(undefined);

export const SidebarProvider = ( {children}: SideBarProviderProps) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const toggleSideBar = () => {
    setSideBarOpen((prevState) => !prevState);
  }

  return (
    <SidebarContext.Provider value={{ sideBarOpen, toggleSideBar}}>
      {children}
    </SidebarContext.Provider>
  )
};