import styled from 'styled-components';

export const CardSmallContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
`

export const CardImage = styled.div`
  width: 100%;
  height: 8rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:  0.5rem 0 0 0.5rem;
  }
`

export const CardBody = styled.div`
  font-family: var(--font-Roboto);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const CardTitle = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
`

export const CardText = styled.div`
  display: flex;
  margin-left: 0.7rem;

  p {
    display: flex;
    align-items: center;
    gap: 0.3rem;

    svg {
      font-size: 0.9rem;
    }
  }
`

export const CardPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  gap: 1rem;

  p {
    margin-left: 0.5rem;
  }

  span {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-right: 0.5rem;
  }
`

export const CardButton = styled.button`
  width: 100%;
  background-color: #D22219;
  bottom: 0px;
  border: none;
  border-radius: 0  0  0.5rem 0 ;
  padding: 0.5rem;
  color: #fff;
  font-weight: bold;
`

