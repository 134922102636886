import styled from 'styled-components';

export const CWhatsApp = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.a`
  position: fixed;
  width: 5vw; /* Tamanho relativo à largura da tela */
  height: 5vw; 
  bottom: 8vh;
  right: 2.5vw;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: background-color 0.3s ease;
  z-index: 9;

  &:hover {
    background-color: #20b858;
  }

  svg {
    font-size: 2.4em;
  }

  min-width: 45px;
  min-height: 45px;
  max-width: 60px;
  max-height: 60px;

  @media screen and (max-width: 768px) {
    font-size: 1.8em;
    bottom: 12vh;
  }
`;

export const Message = styled.div`
  font-family: var(--font-Roboto);
  position: fixed;
  bottom: 9vh;
  right: calc(5vw + 2vw); 
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 1rem; 
  white-space: nowrap;
  animation: fadeInOut 5s ease-in-out infinite;

  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    10%, 90% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
