import { LoadingContainer, Spinner } from './Styles/Loading.styles'

const Loading = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default Loading;