import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';

export const CarouselCustom = styled(Carousel)`
  background-color: #f8f9fa;
  width: 23rem;
  height: 30rem;
  margin-left: 1rem;
  border-radius: 1rem;
  display: flex;
  position: relative;

  .carousel-inner {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .carousel-item {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  /* Centralizar os indicadores horizontalmente */
  .carousel-indicators {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    bottom: 10px;
  }

  .carousel-indicators li {
    background-color: #00ff00;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 15rem;
    border-radius: 0;

    .carousel-inner {
      border-radius: 0;
    }

    .carousel-item {
      border-radius: 0;
    }

    .carousel-item img {
      border-radius: 0;
    }
  }
`;
