import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import { 
  CSSearch, 
  CallSearch, 
  CTitle, 
  SearchContent,
  ButtonStockSearch 
} from './CSearch.styles';

import { TbSearch } from "react-icons/tb";
import { useState } from 'react'; // Importar useState
import { useNavigate } from 'react-router-dom';

const CSearch = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca

  const handleSearch = () => {
    // Navega para a página de estoque com o termo de busca
    if (searchTerm.trim() !== '') { // Verifica se o termo não está vazio
      navigate(`/StockMotorcycle?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  const RedirectStock = () => {
    // Redireciona para a página de estoque sem pesquisa
    navigate('/StockMotorcycle');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(); // Chama a função de pesquisa ao pressionar Enter
    }
  };

  return (
    <CSSearch>
      <CallSearch>
        <CTitle>
          <h2>Chegou a hora de realizar seu sonho</h2>
          <p>E aqui na <span>Garagem 57</span> você pode!</p>
        </CTitle>
        <SearchContent>
          <span>Qual moto está procurando?</span> 
          <div>
            <input
              type="search"
              name="SearchMotorCicle"
              id="senid"
              placeholder='Faça aqui sua pesquisa.'
              value={searchTerm} // Valor do input
              onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o estado ao digitar
              onKeyDown={handleKeyDown}
            />
            <button onClick={handleSearch}>
              <TbSearch />
            </button>
          </div>
        </SearchContent>
      </CallSearch>
      <ButtonStockSearch type='button' onClick={RedirectStock}>
        Ver todas as motos
        <MdOutlineKeyboardDoubleArrowDown size={18} /> 
      </ButtonStockSearch>
    </CSSearch>
  );
};

export default CSearch;
