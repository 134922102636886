import styled from 'styled-components';

export const ModalOverlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: opacity 0.3s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
  font-family: var(--font-Roboto);

  
  h2 {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0.5rem;
  }
`;

export const ModalContent = styled.div`
  background: #fff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  width: 25rem;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  @keyframes slideDown {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    width: 19rem;
  }
`;

export const CloseButton = styled.button`
  background-color: #fff; /* Deixe transparente para um visual limpo */
  color: #D22219; /* Cor do texto */
  padding: 0.1rem;
  border-radius: 1rem;
  cursor: pointer;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  border: none;
  
  svg {
    font-size: 1.9em;
  }

  &:hover {
    opacity: 1; /* Efeito de hover */
  }

`;

export const CButtonSellers = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 2rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #433FBF;
    color: #fff;
    transition: ease 0.3s;

    &:hover{
      background-color: #D22219;
    }

    &:active {
      background-color: #D22219;
    }

    p {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      strong {
        font-weight: bold;
      }
    }

    img {
      width: 100%;
      height: auto;
      max-width: 3rem;
    }

    svg {
      font-size: 1.7em;
    } 
  }
`