import styled from "styled-components";

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  gap: 1rem;
  align-items: center;

  
`;

export const ContentSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  width: 80%;
  
  

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const StepItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  

  &:last-child {
    margin-right: 0;
  }
`;

export const StepTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #949494;
  margin-bottom: 0.6rem;

  font-size: 1.1em;
  font-weight: bold;

  svg {
    font-size: 1.4em;
    color: #000;
  }
`

export const Step = styled.span`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #c1c1c1;
  margin: 2rem;
  padding: 0.5rem;
`;

export const StepText = styled.span`
  font-size: 16px;
  color: #333;
  line-height: 1.1;

  p {
    font-size: 0.9em;
  }
`;