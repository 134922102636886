import { useState, useEffect } from 'react';
import CardsVehicles from '../../../../components/Cards/CardsVehicles/CardsVehicles';
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { Carousel } from 'react-bootstrap';
import { useResize } from '../../../../shared/Functions/Resize/resize';
import { useNavigate } from 'react-router-dom';
import './CustomCarousel.styles.css';
import { ButtonStock, CNewsMotorCicles, CHeaderStoreNews, CStoreNews, CItens } from './StoreNews.styles';
import { apiClient } from '../../../../Services/apiClient';
import Loading from '../../../../components/Loading';

interface VehicleImage {
  image_url: string;
  isMain: boolean; // Adicionando a propriedade isMain
}

interface Vehicle {
  id: string;
  title: string;
  mileage: number;
  year: number;
  price: number;
  images: VehicleImage[]; // Usando a interface VehicleImage
}

const StoreNews = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [loading, setLoading ] = useState(true);
  const windowSize = useResize();
  const mobileMode = windowSize.innerWidth > 1200;
  const navigate = useNavigate();

  // Fazer a chamada à API para buscar os veículos
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await apiClient.get('/vehicles-freelist'); 

        if (!response || !response.data) {
          throw new Error('Erro ao buscar veículos');
        }

        const vehicleData: Vehicle[] = response.data.map((vehicle: any) => ({
          id: vehicle.id,
          title: vehicle.title,
          mileage: vehicle.mileage,
          year: vehicle.year,
          price: vehicle.price,
          images: vehicle.images.map((img: any) => ({
            image_url: img.image_url,
            isMain: img.is_main // Certifique-se de que a propriedade isMain existe
          })),
        }));


        setVehicles(vehicleData);  // Atualiza o estado com os veículos
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
      }
    };

    fetchVehicles();
  }, []);

  const getImageUrl = (images: VehicleImage[]) => {
    const mainImage = images.find(img => img.isMain);
    return mainImage 
      ? mainImage.image_url 
      : (images.length > 0 ? images[0].image_url : 'https://via.placeholder.com/300x200');
  };

  const handleDetailsClick = (id: string) => {
    navigate(`/vehicle-free/${id}`);
  };

  const handleRedirectStock = () => {
    navigate('/StockMotorcycle');
  };

  return (
    <CStoreNews>
      <CHeaderStoreNews>
        <h2>Veja Nossas Novidades</h2>
      </CHeaderStoreNews>

      <CItens>
        <CNewsMotorCicles>
        {loading ? (
          <Loading />
        ) : mobileMode ? (
          <div className='container-grid'>
            {vehicles.slice(0, 4).map((vehicle) => (
             
                <CardsVehicles
                  id={vehicle.id}  // Adicione a propriedade 'id'
                  key={vehicle.id}
                  image={getImageUrl(vehicle.images)}                    
                  title={vehicle.title}
                  km={vehicle.mileage}
                  year={vehicle.year}
                  price={`R$ ${vehicle.price.toLocaleString('pt-BR')}`}
                  onDetailsClick={() => handleDetailsClick(vehicle.id)}
                  highlight={true}
                />
            ))}
          </div>
        ) : (
          <Carousel interval={null} className='custom-carousel'>
            {vehicles.slice(0, 5).map((vehicle) => (
              <Carousel.Item key={vehicle.id}>
                <CardsVehicles
                  id={vehicle.id}  // Adicione a propriedade 'id'
                  image={getImageUrl(vehicle.images)}                    
                  title={vehicle.title}
                  km={vehicle.mileage}
                  year={vehicle.year}
                  price={`R$ ${vehicle.price.toLocaleString('pt-BR')}`}
                  onDetailsClick={() => handleDetailsClick(vehicle.id)}
                  highlight={true}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        </CNewsMotorCicles>

        <ButtonStock type='button' onClick={handleRedirectStock}>
          Ver todo o estoque
          <MdOutlineKeyboardDoubleArrowDown size={28} />
        </ButtonStock>
      </CItens>
    </CStoreNews>
  );
};

export default StoreNews;
