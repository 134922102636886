import styled from 'styled-components';

export const CHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-Roboto);
  padding: 0.8rem;
  background-color: black;
  color: #fff;

  h1 {
    font-weight: bolder;
    font-size: 2em;
  }

  ul {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  li {
    a {
      text-decoration: none;
      font-size: 1.3em;
      font-weight: bold;
      color: white;
      transition: color 0.3s ease;
    }

    a:hover {
      color: red;
    }
  }
  
  .tagScroll {
    cursor: pointer;
  }
`

export const ButtonHeader = styled.button`
  background-color: #25d366;
  color: white;
  padding: 0.6rem 1.3rem;
  border: solid 3px white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.8s ease; 

  &:hover {
    background-color: #128c7e;
  }
`
