import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1.8rem 7rem;
  background-color: #f9f9f9;
  font-family: var(--font-Roboto);
  height: 100vh;
  

  @media (max-width: 768px) {
    padding: 1.3rem;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: 2.5rem;
  color: #949494;
  margin-bottom: 20px;
  font-weight: bold;
  border-bottom: 0.1rem solid #c1c1c1;

  h1 {
    margin:0 1rem 1rem;
  }
  

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;

  strong {
    color: #e74c3c;
  }
`;

