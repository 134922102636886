import Finance from './sections/Finance/Finance';
import BundledServices from './sections/BundledServices/BundledServices';
import CSearch from './sections/Search/CSearch';
import Slides from './sections/Slides/Slides';
import StoreNews from './sections/StoreNews/StoreNews';
import Location from './sections/Location/Location';

const MainPage = () => {
  return(
    <>
      <Slides/>
      <CSearch />
      <StoreNews />
      <Finance />
      <BundledServices/>
      <Location />
    </>
    
  )
}

export default MainPage;