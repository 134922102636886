import { useEffect, useState } from 'react';
import { Vehicle } from './@types/vehicle';
import { Carousel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { apiClient } from '../../Services/apiClient';
import { useNavigate } from 'react-router-dom';
import { MdOutlineLocationOn } from 'react-icons/md';
import { BsTelephoneFill } from 'react-icons/bs';
import { useResize } from '../../shared/Functions/Resize/resize';

import ImageViewer from './components/ImagemViewer/ImageViewer';
import SmallCard from '../../components/SmallCards';

import {
  CDetail,
  CImgDescription,
  CImgOuterOptions,
  CMapsCall,
  CMoreInfor,
  CSmallCards,
  CallSale,
  Case,
  ContainerVehicleDetail,
  DescriptionContainer,
  HeaderDrecription,
  OterOffers,
  ScrollableDescription,
  Title
} from './styles/VehicleDetail.styles';

import { CarouselCustom } from './styles/Carousel.styles';
import Loading from '../../components/Loading';


interface Image {
  id: string;
  vehicle_id: string;
  image_url: string;
  created_at: string;
}

interface VehicleImage {
  image_url: string;
  isMain: boolean; // Adicionando a propriedade isMain
}

const VehicleDetail = () => {
  const [vehicle, setVehicle] = useState<Vehicle | null>(null); // Estado para armazenar os dados do veículo
  const { id } = useParams<{ id: string }>(); // Pegar o id da URL
  const [ vehiclesCards, setVehiclesCards] = useState<Vehicle[]>([]);  // Estado para armazenar os veículos
  const [randomVehicles, setRandomVehicles] = useState<Vehicle[]>([]); // Estado para veículos embaralhados
  const [loading, setLoading ] = useState(true);

  const windowSize = useResize();
  const mobileMode = windowSize.innerWidth > 728;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        setVehicle(null); // Força a renderização do componente para exibir carregamento
        const response = await apiClient.get(`/vehicle-free/${id}`);
        const vehicleData = response.data;

        const formattedImages = vehicleData.images?.map((image: Image, index: number) => ({
          url: image.image_url,
          isMain: index === 0,
        })) || [];

        setVehicle({
          ...vehicleData,
          images: formattedImages,
        });
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os detalhes do veículo:", error);
      }
    };

    fetchVehicleDetails();
  }, [id]); // `id` como dependência, para atualizar sempre que ele mudar

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await apiClient.get('/vehicles-freelist');

        if (!response || !response.data) {
          throw new Error('Erro ao buscar veículos');
        }

        const vehicleData: Vehicle[] = response.data.map((vehicle: any) => ({
          id: vehicle.id,
          title: vehicle.title,
          mileage: vehicle.mileage,
          year: vehicle.year,
          price: vehicle.price,
          images: vehicle.images.map((img: any) => ({
            image_url: img.image_url,
            isMain: img.is_main // Certifique-se de que a propriedade isMain existe
          })),
        }));

        setVehiclesCards(vehicleData);

        // Filtra para remover o veículo atual e depois embaralha o array
        const filteredVehicles = vehicleData.filter(
          (vehicleCard) => vehicleCard.id !== id
        );
        setRandomVehicles(shuffleArray(filteredVehicles).slice(0, 4)); // Seleciona 4 veículos aleatórios
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
      }
    };

    fetchVehicles();
  }, [id]);

  // Função para embaralhar o array de veículos
  const shuffleArray = (array: Vehicle[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  if (!vehicle) return <Loading/>; // Exibir carregando enquanto os dados não são carregados

  const handleDetailsClick = (id: string) => {
    navigate(`/vehicle-free/${id}`);
  };

  const redirectTo = (url: string) => {
    window.open(url, '_blank');
  };


  const getImageUrl = (images: VehicleImage[]) => {
    const mainImage = images.find(img => img.isMain);
    return mainImage 
      ? mainImage.image_url 
      : (images.length > 0 ? images[0].image_url : 'https://via.placeholder.com/300x200');
  };

  return (
    <ContainerVehicleDetail>
      <CImgOuterOptions>
        <CImgDescription>
          <Title>
            <h2>{vehicle.title}</h2>
            <p>R$ {vehicle.price.toLocaleString()}</p>
          </Title>
          { loading ? (
              <Loading/>
            ) : mobileMode ? (
            vehicle.images && vehicle.images.length > 0 && (
              <ImageViewer images={vehicle.images} />
            )
          ) : (
            vehicle.images && vehicle.images.length > 0 && (
              <CarouselCustom interval={null} indicators={false} className='carousel slide'>
                {vehicle.images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      src={image.url}
                      alt={`Vehicle image ${index + 1}`}
                      className="d-block w-100"
                      loading='lazy'
                    />
                  </Carousel.Item>
                ))}
              </CarouselCustom>
            )
          )}
        </CImgDescription>
        <CallSale>
          <CMapsCall>
            <h3>Garagem 57</h3>
            <span>A casa do motociclista</span>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.0946944371826!2d-35.721320624973075!3d-9.672949990416324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x701453eab74e5a5%3A0x33c1f54279cd5094!2sGaragem57!5e0!3m2!1spt-BR!2sbr!4v1726548260435!5m2!1spt-BR!2sbr"
              loading="lazy">
            </iframe>
            <button onClick={() => redirectTo('https://maps.app.goo.gl/sBB8FZu5VD9sCVxK7')}>
              Visite nossa loja
            </button>
            <address>
              <a href="https://maps.app.goo.gl/sBB8FZu5VD9sCVxK7" target='_blank'>
                <MdOutlineLocationOn size={20} />
                R. Epaminondas Gracindo, 57 - Pajuçara, Maceió - AL, 57022-183
              </a>
            </address>
            <span>
              <a href="tel:+5582999994660">
                <BsTelephoneFill />
                (82) 9 9999-4660
              </a>
            </span>
          </CMapsCall>
        </CallSale>
      </CImgOuterOptions>

      <CMoreInfor>
        <div>
          <CDetail>
            <Case>
              <p><strong>Ano</strong> {vehicle.year}</p>
              <p><strong>Cor</strong> {vehicle.color}</p>
              <p><strong>Aceita troca</strong> {vehicle.acceptsExchange ? 'Sim' : 'Não'}</p>
              <p><strong>Cilindrada</strong> {vehicle.displacement} cc</p>
              <p><strong>Quilometragem</strong> {vehicle.mileage.toLocaleString('pt-BR')} km</p>
              <p><strong>Marca</strong> {vehicle.brand}</p>
              <p><strong>Tipo</strong> {vehicle.type}</p>
            </Case>
            <ul>
              {vehicle.extraItems?.map((item, index) => (
                <li key={index}>{item}</li>
              )) || <li></li>} {/* Mensagem padrão se não houver itens extras */}
            </ul>
          </CDetail>

          <DescriptionContainer>
            <HeaderDrecription><p>Outros Detalhes</p></HeaderDrecription>
            <ScrollableDescription><p>{vehicle.description}</p></ScrollableDescription>
          </DescriptionContainer>
        </div>
        <div>
          <CSmallCards>
            <OterOffers>
              <h4>Outras Ofertas</h4>
              {randomVehicles.map((vehicleCard) => ( // Use o array embaralhado
                <SmallCard
                  key={vehicleCard.id}
                  id={vehicleCard.id}
                  title={vehicleCard.title}
                  price={`R$ ${Number(vehicleCard.price ?? 0).toLocaleString('pt-BR')}`}
                  km={vehicleCard.mileage}
                  year={vehicleCard.year}
                  image={getImageUrl(vehicleCard.images)}   
                  onDetailsClick={() => handleDetailsClick(vehicleCard.id)}
                />
              ))}
            </OterOffers>
          </CSmallCards>
        </div>
      </CMoreInfor>
    </ContainerVehicleDetail>
  );
}

export default VehicleDetail;