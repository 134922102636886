import { SidebarProvider } from '../../shared/Context/SideBarContext';
import { useResize } from '../../shared/Functions/Resize/resize';

import NavBar from '../../components/Header/Navbar/Navbar';
import Sidebar from '../../components/Header/Sidebar/Sidebar';

const CHeader = () => {
  const windowSize = useResize();
  const desktopMode = windowSize.innerWidth > 1024;

  return (
    <header>
      {desktopMode ? 
        <NavBar/>
        :
        <SidebarProvider>
          <Sidebar />
        </SidebarProvider>
      }
      
     
    </header>
  )
};

export default CHeader;