import { Link, useLocation } from 'react-router-dom';
import { BsWhatsapp, BsInstagram, BsFacebook } from "react-icons/bs";
import useSideBar from '../../../../shared/Hooks/useSiderBar';
import { ButtonFacebook, ButtonInstagram, ButtonWht, CButtonsSubmenu, CNavSubmenu, CSubmenu } from './SubMenu.styles';



const Submenu = () => {
  const { toggleSideBar } = useSideBar();
  const location = useLocation();
  
  function scrollToSection(sectionId: string) {
    if (sectionId === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      };
    };
    toggleSideBar();
  };


  // Função para redirecionamento e fechar o menu
  const closeMenu = () => {
    toggleSideBar();
  }

  const whatsappNumber = '+5582999994660';

  const handleClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    closeMenu(); // Fecha o menu após abrir o WhatsApp
  };

  return (
    <CSubmenu>
      <CNavSubmenu>
        <nav>
          <ul>
            <li>
              <Link to='/' onClick={closeMenu}>Início</Link>
            </li>
            <li>
              <Link to='/StockMotorcycle' onClick={closeMenu}>Ver todas as motos</Link>
            </li>
            {location.pathname === '/' &&
              <li>
               <Link to='#' onClick={() => scrollToSection('services')}>Serviços</Link>
              </li>
            }
            <li>
              <Link to='/About' onClick={closeMenu}>Sobre</Link>
            </li>
            <li>
              <Link to='/Dating' onClick={closeMenu}>Encontros</Link>
            </li>
          </ul>
        </nav>
      </CNavSubmenu>

      <CButtonsSubmenu>
        <ButtonWht type="button" onClick={handleClick}>
          <BsWhatsapp size={32} />
        </ButtonWht>
        <ButtonInstagram type='button' onClick={closeMenu}>
          <BsInstagram size={32} />
        </ButtonInstagram>
        <ButtonFacebook type='button' onClick={closeMenu}>
          <BsFacebook size={32} />
        </ButtonFacebook>
      </CButtonsSubmenu>
    </CSubmenu>
  );
}

export default Submenu;
