import saleImg from '../../../../assets/img/components/Sale.png';
import finance from '../../../../assets/img/components/Finance.jpg';
import { 
  COneCase,
  CTwoCase, 
  CFinance, 
  ContainerFinanceSale, 
  CFinanceContent,
  CSaleContent,
  CSale
} from './Finance.styles';

import { useNavigate } from 'react-router-dom';

const Finance = () => {
  const navigate = useNavigate();

  const RedirectionFinance = () =>{
    navigate('/FinanceForm')
  };

  const RedirectionSale = () => {
    navigate('/SaleForm')
  };

  return (
    <ContainerFinanceSale>
      <COneCase>
        <CFinanceContent>
          <h2>Realize seu sonho!</h2>
          <p>Faça agora a simulação de seu financiamento e 
            descubra como podemos ajudar você a conquistar 
            sua moto dos sonhos.
          </p>
          <button type='button' onClick={RedirectionFinance}>
            Fazer Simulação
          </button>
        </CFinanceContent>
        <CFinance>
          <img src={saleImg} alt="Imagem de homem comprando moto" />
        </CFinance>
      </COneCase>

      <CTwoCase>
        <CSaleContent>
          <h3>Quer Vender Sua Moto?</h3>
          <p>Deixe sua moto em boas mãos! Aproveite nossas 
            condições especiais e venda com facilidade na nossa 
            loja. Nós cuidamos de tudo para você.
          </p>
          <button type='button' onClick={RedirectionSale}>
              Quero vender minha moto
          </button>
        </CSaleContent>
        <CSale>
          <img src={finance} alt="Imagem de homem comprando moto" />
        </CSale>
      </CTwoCase>
    </ContainerFinanceSale>
  )
}

export default Finance;