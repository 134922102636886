import { CCardsStock, CContent, CItemsStock, ContainerStock } from './StockMotorcycle.styles';
import SearchStock from './Components/SearchStock/SearchStock';
import Pagination from './Components/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { apiClient } from '../../Services/apiClient';
import CardsVehicles from '../../components/Cards/CardsVehicles/CardsVehicles';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';

interface VehicleImage {
  image_url: string;
  isMain: boolean;
}

interface Vehicle {
  id: string;
  title: string;
  mileage: number;
  year: number;
  price: number;
  images: VehicleImage[];
}

const StockMotorcycle = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [currentVehicles, setCurrentVehicles] = useState<Vehicle[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(9);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para o termo de busca
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Captura o termo de busca da query string da URL
    const query = new URLSearchParams(location.search);
    const searchFromUrl = query.get('search') || '';
    setSearchTerm(searchFromUrl); // Atualiza o termo de busca
  }, [location.search]); // Roda sempre que a query string mudar

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await apiClient.get('/vehicles-freelist');
        if (!response || !response.data) {
          throw new Error('Erro ao buscar veículos');
        }

        const vehicleData: Vehicle[] = response.data.map((vehicle: any) => ({
          id: vehicle.id,
          title: vehicle.title,
          mileage: vehicle.mileage,
          year: vehicle.year,
          price: vehicle.price,
          images: vehicle.images.map((img: any) => ({
            image_url: img.image_url,
            isMain: img.is_main
          })),
        }));

        
        setVehicles(vehicleData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
      }
    };

    fetchVehicles();
  }, []);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Filtrar veículos com base no termo de busca
    const filteredVehicles = vehicles.filter(vehicle =>
      vehicle.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setCurrentVehicles(filteredVehicles.slice(indexOfFirstItem, indexOfLastItem));
  }, [currentPage, vehicles, itemsPerPage, searchTerm]); // Adicione searchTerm como dependência

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setItemsPerPage(newWidth < 768 ? 3 : 9);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDetailsClick = (id: string) => {
    navigate(`/vehicle-free/${id}`);
  };

  const getImageUrl = (images: VehicleImage[]) => {
    const mainImage = images.find(img => img.isMain);
    return mainImage
      ? mainImage.image_url
      : (images.length > 0 ? images[0].image_url : 'https://via.placeholder.com/300x200');
  };

  // Função para atualizar o termo de busca
  const updateSearchTerm = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reseta a página atual para a primeira
    navigate(`/StockMotorcycle?search=${encodeURIComponent(term)}`); // Atualiza a URL
  };

  const resetSearch = () => {
    setSearchTerm(''); // Reseta o termo de busca
    setCurrentPage(1); // Reseta a página atual para a primeira
    navigate('/StockMotorcycle'); // Navega para a página sem termo de busca
  };

  return (
    <ContainerStock>
      <SearchStock updateSearchTerm={updateSearchTerm} resetSearch={resetSearch} />
      { loading ? (
          <Loading/>
      ) : (
        <CItemsStock>
          <CContent>
            <CCardsStock>
              {currentVehicles.map((vehicle) => (
                <div key={vehicle.id}>
                  <CardsVehicles
                    id={vehicle.id}
                    image={getImageUrl(vehicle.images)} 
                    title={vehicle.title}
                    km={vehicle.mileage}
                    year={vehicle.year}
                    price={`R$ ${vehicle.price.toLocaleString('pt-BR')}`}
                    onDetailsClick={() => handleDetailsClick(vehicle.id)}
                    highlight={false}
                  />
                </div>
              ))}
            </CCardsStock>
          </CContent>
          
          <Pagination
            data={vehicles.filter(vehicle => 
              vehicle.title.toLowerCase().includes(searchTerm.toLowerCase())
            )}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </CItemsStock>
      )}
    </ContainerStock>
  );
};

export default StockMotorcycle;
