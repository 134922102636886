import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './screens/MainPage/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyles from './shared/Styles';
import CHeader from './containers/CHeader/CHeader';
import Footer from './containers/Footer/Footer';
import VehicleDetail from './screens/VehicleDetail/VehicleDetail';
import AboutPage from './screens/About/About';
import ButtonWhatsApp from './components/ButtonWhatsApp/ButtonWhatsApp';
import Dating from './screens/Dating/Dating';
import { ModalWhatsAppProvider } from './shared/Context/ModalWhatsApp';
import FinanceForm from './screens/FinanceForm/FinanceForm';
import SaleForm from './screens/SaleForm/SaleForm';
import StockMotorcycle from './screens/Stock/StockMotorcycle';
import CookieConsent from './components/CokiesConsent';
import ScrollToTop from './shared/Functions/ScrollToTop';


function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <ScrollToTop />
      <ModalWhatsAppProvider>
        <CHeader />
          <ButtonWhatsApp />
          <CookieConsent />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/vehicle-free/:id" element={<VehicleDetail />} />
            <Route path='/About' element={<AboutPage />} />
            <Route path='/Dating' element={<Dating />} />
            <Route path='/FinanceForm' element={<FinanceForm/>} />
            <Route path='/SaleForm' element={<SaleForm/>} /> 
            <Route path='/StockMotorcycle' element={<StockMotorcycle/>}/>
          </Routes>
        <Footer />
      </ModalWhatsAppProvider>
    </BrowserRouter>
  );
};

export default App;
