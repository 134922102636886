import styled from 'styled-components';

export const CFooter = styled.footer`
  display: flex;
  background-color: #000;
  color: white;
  padding: 0.8rem;
  font-family: var(--font-Roboto);
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: center;

  a {
    margin-left: 1rem;
    text-decoration: none;
    color: #fff;
    

    &:hover {
      color: red;
    }
  }

  @media (max-width: 768px) {
    p {
      text-align: center;
    }
  }
`;
