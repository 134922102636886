import { 
  Container, 
  ContentWrapper, 
  VideoWrapper, 
  TextWrapper,
  Title
} from './Dating.styles';

const Dating = () => {
  return (
    <Container>
      <ContentWrapper>
        <VideoWrapper>
          <video controls>
            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoWrapper>
        <TextWrapper>
          <Title><h2>Encontros Garagem 57</h2></Title>
          <p>
            Encontre-se com apaixonados por motociclismo todas as quintas-feiras! 
            Junte-se a nós, a partir das 19h até às 23h, para um evento especial 
            cheio de energia, diversão e boas companhias. Nossa reunião é o lugar 
            perfeito para quem vive a adrenalina sobre duas rodas e quer compartilhar 
            experiências com outros entusiastas do motociclismo. Traga a família e os 
            amigos, pois o ambiente é acolhedor e familiar!
          </p>
          <p>
            Além de muita conversa sobre motos, você poderá explorar nossos produtos 
            exclusivos e ver de perto as motocicletas dos seus sonhos. E tem mais: toda 
            quinta-feira, temos promoções imperdíveis na loja, com novidades a cada semana!
          </p>
          <p>
            Não perca a chance de fazer amizades, aproveitar o clima descontraído e garantir
            aquela oferta especial. Venha viver o motociclismo com a gente!
          </p>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Dating;
