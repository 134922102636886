import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { financeFormSchema } from './schema/ValidationSchemaFinance';
import StepsComponent from './components/StepsComponent';
import { MdErrorOutline } from "react-icons/md";

import { 
  ContainerFinanceForm, 
  Container, 
  Content, 
  FormStyled, 
  FormTelephone, 
  Telephone, 
  CheckboxConfirm, 
  Title, 
  ButtonForm 
} from './FinanceForm.styles';
import { ErroFormFinance } from './schema/ErrorMessage';


const FinanceForm = () => {
  const formik = useFormik({
    initialValues: {
      nome: '',
      telefone: '',
      whatsapp: false,
      email: '',
      cpf: '',
      dataNascimento: '',
      tipoVeiculo: ''
    },
    validationSchema: financeFormSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      alert("Formulário enviado com sucesso!");
      resetForm(); 
    }
  });
  

  return (
    <Container>
      <Content>
        <Title>
          <h2>Financiamento</h2>
        </Title>
        <ContainerFinanceForm>
          <form onSubmit={formik.handleSubmit}>
            <FormStyled>
              <label>Qual veículo você procura?</label>
              <input 
                type="text" 
                name="tipoVeiculo" 
                value={formik.values.tipoVeiculo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Informe - Marca, modelo e ano." 
              />
              {formik.touched.tipoVeiculo && formik.errors.tipoVeiculo ? (
                <ErroFormFinance>
                  <MdErrorOutline/>
                  {formik.errors.tipoVeiculo}
                </ErroFormFinance>
              ) : null}
            </FormStyled>

            <FormStyled>
              <label>Nome:</label>
              <input 
                type="text" 
                name="nome" 
                value={formik.values.nome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.nome && formik.errors.nome ? (
                <ErroFormFinance>
                  <MdErrorOutline/>
                  {formik.errors.nome}
                </ErroFormFinance>
              ) : null}
            </FormStyled>

            <FormTelephone>
              <Telephone>
                <label>Telefone:</label>
                <InputMask
                  mask="(99) 99999-9999" 
                  type="tel" 
                  name="telefone" 
                  value={formik.values.telefone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.telefone && formik.errors.telefone ? (
                  <ErroFormFinance>
                    <MdErrorOutline/>
                    {formik.errors.telefone}
                  </ErroFormFinance>
                ) : null}
              </Telephone>
              
              <CheckboxConfirm>
                <label>
                  <input 
                    type="checkbox" 
                    name="whatsapp" 
                    checked={formik.values.whatsapp}
                    onChange={formik.handleChange}
                  />
                  O número é WhatsApp?
                </label>
              </CheckboxConfirm>   
            </FormTelephone>

            <FormStyled>
              <label>Email:</label>
              <input 
                type="email" 
                name="email" 
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <ErroFormFinance>
                  <MdErrorOutline/>
                  {formik.errors.email}
                </ErroFormFinance>
              ) : null}
            </FormStyled>

            <FormStyled>
              <label>CPF:</label>
              <InputMask
                mask="999.999.999-99" 
                type="text" 
                name="cpf" 
                value={formik.values.cpf}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
              />
              {formik.touched.cpf && formik.errors.cpf ? (
                <ErroFormFinance>
                  <MdErrorOutline/>
                  {formik.errors.cpf}
                </ErroFormFinance>
              ) : null}
            </FormStyled>

            <FormStyled>
              <label>Data de Nascimento:</label>
              <input 
                type="date" 
                name="dataNascimento" 
                value={formik.values.dataNascimento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dataNascimento && formik.errors.dataNascimento ? (
                <ErroFormFinance>
                  <MdErrorOutline/>
                  {formik.errors.dataNascimento}
                </ErroFormFinance>
              ) : null}
            </FormStyled>

            <ButtonForm type="submit">
              Enviar
            </ButtonForm>
          </form>
          <StepsComponent/>
        </ContainerFinanceForm>     
      </Content>
    </Container>
  );
};

export default FinanceForm;
