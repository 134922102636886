import { useState, useEffect } from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import { Button, Message, CWhatsApp } from './ButtonWhatsApp.styles';
import { useModalWhatsApp } from '../../shared/Context/ModalWhatsApp';



const ButtonWhatsApp = () => {
  const [ showMessage, setShowMessage ] = useState(false);
  const [ repeatCount, setRepeatCount ] = useState(0);
  const { showModal } = useModalWhatsApp();
 

  useEffect(() => {
    // Função que exibe a mensagem por 3 segundos
    const handleMessageDisplay = () => {
      setShowMessage(true);

      // Esconde a mensagem após 3 segundos
      const hideMessageTimeout = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return hideMessageTimeout;
    };

    if (repeatCount < 5) { // Limita o ciclo a 3 repetições
      // Exibe a mensagem a cada 10 segundos
      const interval = setInterval(() => {
        handleMessageDisplay();

        setRepeatCount((prevCount) => prevCount + 1);
      }, 10000);

      return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }
  }, [repeatCount]); // Atualiza quando o contador de repetições muda

  //função abre e fehca modal

 
  
  return (
    <CWhatsApp>
    <Button onClick={showModal}>
      <FaWhatsapp size={36} />
    </Button>
    {showMessage && <Message>Fale agora com um vendedor!</Message>} 
    </CWhatsApp>
    
  );
};

export default ButtonWhatsApp;