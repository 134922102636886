import { useEffect, useRef } from 'react';
import { IoClose } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";

import { 
  CloseButton, 
  ModalContent, 
  ModalOverlay, 
  CButtonSellers 
} from './ModalWhatsApp.styles';


import sallerJr from '../../assets/img/saller/JR.png';
import sallerBeto from '../../assets/img/saller/BETO.png';

interface ModalWhatsAppRequest {
  show: boolean;
  onClose: () => void;
}

const ModalWhatsApp = ({ show, onClose }: ModalWhatsAppRequest) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  // Função para redirecionar para o WhatsApp 
  const enviarMensagemWhatsAppOne = (mensagem: string) => {
    const numero = '+5582999994660'; // Número de WhatsApp
    const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
  };
 
  const enviarMensagemWhatsAppTwo = (mensagem: string) => {
    const numero = '+5582999136234'; // Número de WhatsApp
    const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
  };
 
  
  useEffect(() => {
    if (show && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [show, onClose]);

  if (!show) return null;

  return (
    <ModalOverlay
      show={show}
      onClick={onClose}
      aria-modal="true"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose} ref={closeButtonRef}>
          <IoClose />
        </CloseButton>
        <h2 id="modal-title">Entre em contato</h2>
        <p id="modal-description">Fale diretamente com um de nossos vendedores no WhatsApp!</p>
        <CButtonSellers>
          <button type='button' onClick={() => enviarMensagemWhatsAppOne('Olá, estou interessado em suas motos!')}>
            <img src={sallerJr} alt="imagem do usuário Junior" />
            <p>Vendedor <strong>Junior</strong></p>
            <BsWhatsapp />
          </button>

          <button type='button' onClick={() => enviarMensagemWhatsAppTwo('Olá, estou interessado em suas motos!')}>
            <img src={sallerBeto} alt="Imagem do usuário Luiz Roberto" />
            <p>Vendedor <strong>Luiz Roberto</strong></p>
            <BsWhatsapp />
          </button>
        </CButtonSellers>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalWhatsApp;
