import { MotorcycleCardRequest } from './@types/CardsVehicles';
import { 
  CardBody, 
  CardButton, 
  CardContainer, 
  CardImage, 
  CardText, 
  CardTitle,
  CardPrice, 
  HighlightBanner
} from './CardsVehicles.styles';
import { SlSpeedometer } from "react-icons/sl";



const CardsVehicles = ({       
  image,
  title,
  km,
  year,
  price,
  onDetailsClick,
  highlight 
}: MotorcycleCardRequest) => {
  return (
    <CardContainer>
      <CardImage>
        <img src={image} alt={title} loading='lazy'/>
        {highlight && <HighlightBanner>Novidade</HighlightBanner>}
      </CardImage>
      <CardBody>
        <CardTitle>
          {title}
        </CardTitle>
        <CardText>
          <p><SlSpeedometer size={24}/> {km} km</p>
          <span>{year}</span> 
        </CardText>
        <CardPrice><p>{price}</p></CardPrice>
        <CardButton onClick={onDetailsClick}>Ver detalhes</CardButton>
      </CardBody>
    </CardContainer>
  );
};

export default CardsVehicles;
