import * as Yup from 'yup';

export const saleFormSchema = Yup.object().shape({
  tipoVeiculo: Yup.string()
    .required('O campo de veículo é obrigatório')
    .min(3, 'Informe marca, modelo e ano do veículo'),
  nome: Yup.string()
    .required('O campo de nome é obrigatório')
    .min(2, 'O nome deve ter pelo menos 2 caracteres'),
  telefone: Yup.string()
    .required('O campo de telefone é obrigatório')
    .transform(value => value.replace(/\D/g, ''))
    .matches(/^[0-9]+$/, 'Telefone deve conter apenas números')
    .min(10, 'O telefone deve ter pelo menos 10 dígitos')
    .max(11, 'O telefone deve ter no máximo 11 dígitos'), 
  email: Yup.string()
    .email('Email inválido')
    .required('O campo de email é obrigatório'),
});