import styled from 'styled-components';

export const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #222;
  color: #fff;
  padding: 15px;
  text-align: center;
  z-index: 1000;
  font-family: Arial, sans-serif;
`;

export const Message = styled.p`
  font-size: 16px;
`;

export const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  
  &:hover {
    background-color: #45a049;
  }
`;
