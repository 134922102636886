import styled, { keyframes } from 'styled-components';

// Animação do spinner
export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


export const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #433fbf;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;