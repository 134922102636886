import InputMask from 'react-input-mask';

import { useFormik } from 'formik';

import StepsComponent from './Components/StepsComponentSale';

import { saleFormSchema } from './Schema/ValidationSchemaSale';
import { MdErrorOutline } from "react-icons/md";

import { 
  ContainerFinanceForm, 
  Container, 
  Content, 
  FormStyled, 
  FormTelephone, 
  Telephone, 
  CheckboxConfirm, 
  Title, 
  ButtonForm 
} from './SaleForm.styles';

import { ErroFormSale } from './Schema/ErrorMessage';


const SaleForm = () => {
  const formik = useFormik({
    initialValues: {
      nome: '',
      telefone: '',
      whatsapp: false,
      email: '',
      tipoVeiculo: ''
    },
    validationSchema: saleFormSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      alert("Formulário enviado com sucesso!");
      resetForm(); 
    }
  });
  

  return (
    <Container>
      <Content>
        <Title>
          <h2>Venda sua moto</h2>
        </Title>
        <ContainerFinanceForm>
          <form onSubmit={formik.handleSubmit}>
            <FormStyled>
              <label>Dados do veículo:</label>
              <input 
                type="text" 
                name="tipoVeiculo" 
                value={formik.values.tipoVeiculo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Informe - Marca, modelo e ano." 
              />
              {formik.touched.tipoVeiculo && formik.errors.tipoVeiculo ? (
                <ErroFormSale>
                  <MdErrorOutline/>
                  {formik.errors.tipoVeiculo}
                </ErroFormSale>
              ) : null}
            </FormStyled>

            <FormStyled>
              <label>Nome:</label>
              <input 
                type="text" 
                name="nome" 
                value={formik.values.nome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.nome && formik.errors.nome ? (
                <ErroFormSale>
                  <MdErrorOutline/>
                  {formik.errors.nome}
                </ErroFormSale>
              ) : null}
            </FormStyled>

            <FormTelephone>
              <Telephone>
                <label>Telefone:</label>
                <InputMask
                  mask="(99) 99999-9999" 
                  type="tel" 
                  name="telefone" 
                  value={formik.values.telefone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.telefone && formik.errors.telefone ? (
                  <ErroFormSale>
                    <MdErrorOutline/>
                    {formik.errors.telefone}
                  </ErroFormSale>
                ) : null}
              </Telephone>
              
              <CheckboxConfirm>
                <label>
                  <input 
                    type="checkbox" 
                    name="whatsapp" 
                    checked={formik.values.whatsapp}
                    onChange={formik.handleChange}
                  />
                  O número é WhatsApp?
                </label>
              </CheckboxConfirm>   
            </FormTelephone>

            <FormStyled>
              <label>Email:</label>
              <input 
                type="email" 
                name="email" 
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <ErroFormSale>
                  <MdErrorOutline/>
                  {formik.errors.email}
                </ErroFormSale>
              ) : null}
            </FormStyled>

            <ButtonForm type="submit">
              Enviar
            </ButtonForm>
          </form>
          <StepsComponent/>
        </ContainerFinanceForm>     
      </Content>
    </Container>
  );
};

export default SaleForm;
