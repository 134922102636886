import { useState } from 'react';
import { TbSearch } from "react-icons/tb";
import { CSSearch, CallSearch, CTitle, SearchContent, ButtonStockSearch, ReturnList } from './SearchStock.styles';

// Define a interface para as propriedades do SearchStock
interface SearchStockProps {
  updateSearchTerm: (term: string) => void; // Função para atualizar o termo de busca
  resetSearch: () => void; // Função para resetar a busca e mostrar todos os itens
}

const SearchStock = ({ updateSearchTerm, resetSearch }: SearchStockProps) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = () => {
    updateSearchTerm(searchInput); // Atualiza o termo de busca no componente pai
    setSearchInput(''); // Limpa o campo de entrada após a pesquisa
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(); // Chama a função de pesquisa ao pressionar Enter
    }
  };

  return (
    <CSSearch>
      <CallSearch>
        <CTitle>
          <h2>Chegou a hora de realizar seu sonho</h2>
          <p>E aqui na <span>Garagem 57</span> você pode!</p>
        </CTitle>
        <SearchContent>
          <span>Qual moto está procurando?</span>
          <div>
            <input
              type="search"
              placeholder='Faça aqui sua pesquisa.'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)} // Atualiza o valor do input
              onKeyDown={handleKeyDown} // Adiciona o manipulador de eventos de teclado
            />
            <button onClick={handleSearch}>
              <TbSearch />
            </button>
          </div>
          {/* Link para ver a lista completa */}
          <ReturnList>
            <a
              href="#"
              onClick={resetSearch}
            >
              Ver lista completa
            </a>
          </ReturnList>
        </SearchContent>
      </CallSearch>
    </CSSearch>
  );
};

export default SearchStock;
