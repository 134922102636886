import { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import ModalWhatsApp from '../../../components/ModalWhatsApp/ModalWhatsApp';

interface ModalWhatsAppContextData {
  showModal: () => void;
  hideModal: () => void;
}

const ModalWhatsAppContext = createContext<ModalWhatsAppContextData | undefined>(undefined);

interface ModalWhatsAppProviderProps {
  children: ReactNode;
}

export const ModalWhatsAppProvider = ({ children }: ModalWhatsAppProviderProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <ModalWhatsAppContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ModalWhatsApp show={isModalVisible} onClose={hideModal} />
    </ModalWhatsAppContext.Provider>
  );
};

export const useModalWhatsApp = () => {
  const context = useContext(ModalWhatsAppContext);
  if (!context) {
    throw new Error('useModalWhatsApp must be used within a ModalWhatsAppProvider');
  }
  return context;
};
