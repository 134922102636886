import styled from 'styled-components';

export const ContainerVehicleDetail = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem 6rem;
  gap: 2rem;
  font-size: 1em;

  @media (max-width: 768px) {
    padding: 1rem ;
  }
`

export const Title = styled.div`
  font-size: 1em;
  margin-bottom: 1.2rem;
  font-weight: bold;
  border-bottom: 0.1rem solid #c1c1c1;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-Roboto);

  p {
    margin:0 1rem 1rem;
    font-size: 1.6em;
    color: #c1c1c1;
  }

  h2 {
    margin:0 1rem 1rem;
    font-size: 1.7em;
    color: #c1c1c1;
  }
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.1rem;
    }

    p {
      font-size: 1.1rem;
      white-space: nowrap;
    }
  }
`

export const CImgOuterOptions = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

export const CMoreInfor = styled.div`
  display: grid;
  grid-template-columns: 65% 30%;
  column-gap: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const CSmallCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

export const CImgDescription = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const CallSale = styled.div`
  border:solid 0.1rem #c1c1c1;
  font-family: var(--font-Roboto);
  padding: 1rem;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`

export const CMapsCall = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #c1c1c1;
  }

  span {
    margin-bottom: 0.3rem;
  }

  p {
    strong {
      font-weight: bold;
      font-size: 1.4em;
      margin-bottom: 0.5rem;
    }
  }

  iframe {
    width: 100%;
    height: 15rem;
    margin-top: 1rem;
  }
  
  address{
    margin: 1rem 0;
    text-align: center;

    a {
      text-decoration: none;
      cursor: pointer;
      color: #000;

      &:hover{
        color: #433FBF;
      }
    }
  }
 
span {
  

  a {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    text-decoration: none;
    cursor: pointer;
    color: #000;

    &:hover{
      color: #433FBF;
    }
  }
}

 button {
  width: 100%;
  border: none;
  padding: 0.5rem;
  background-color: #D22219;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;


  color: #fff;


  &:hover {
    background-color: #D21121;
  }
 }
`

export const OterOffers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  
  h4 {
    font-family: var(--font-Roboto);
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    color: #c1c1c1 ;
    margin-bottom: 0.5rem;
  }
`

export const CDetail = styled.div`
  font-family: var(--font-Roboto);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  strong {
    font-weight: bold;
    color: #c1c1c1;
    text-transform: uppercase;
  }
`

export const Case = styled.div `
  display: flex;
  align-items: center;
  gap: 3rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const HeaderDrecription = styled.div`
  p {
    font-family: var(--font-Roboto);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3em;
    margin-top: 1rem;
    color: #c1c1c1;
  }
`

export const DescriptionContainer = styled.pre`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  word-break: normal;
`;

export const ScrollableDescription = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: var(--font-Roboto);
  overflow-y: auto;
  min-height: 100vh;

  p {
    white-space: pre-wrap; /* Preserva quebras de linha */
    word-break: break-word; /* Permite quebra de palavras longas */
  }

  @media (max-width: 768px) {
    p {
      word-break: break-word;
      text-align: 0.2rem;
    }
  }
`;

