import styled from 'styled-components';

export const CSubmenu = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
`

export const CNavSubmenu = styled.div`
  font-family: var(--font-Roboto);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;


  li {
    border-bottom: solid 1px #c1c1c1;
    display: flex;
    width: 85vw;

    &:last-child {
      border-bottom: none;
    }
  }

  
  li a {
    text-decoration: none;
    
    padding: 1rem 0;
    font-size: 1.2rem;
    color:  #23262B;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: background-color 0.8s ease; 
    

    &:active {
      background-color: #c1c1c1;
    }
  }
`
export const CButtonsSubmenu = styled.div`
  width: 100%;
  display: flex;
  margin-top:1rem;

  button {
    width: 100%;
    padding: 0.5rem 0;
    border: none;
    cursor: pointer;
    color: #fff;
  }
`

export const ButtonInstagram =styled.button`
  border: none;

  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);

  svg {
    color: #fff;
  }
`

export const ButtonFacebook = styled.button`
  border: none;

  background-color: #2050b3;

  svg {
    color: #fff;
  }
`

export const ButtonWht = styled.button`
  border: none;
  background-color: #25d366;

  svg {
    color: #fff;
  }
`