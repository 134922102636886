import { useState, useEffect } from 'react';
import { Banner, Message, Button } from './styles/CookiesConsent.styles'; // Importe os estilos

const CookieConsent = () => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  // Verifica se o consentimento já foi dado
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setIsAccepted(true);
    }
  }, []);

  // Função para aceitar o consentimento
  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsAccepted(true);

    // Enviar para o Google Analytics
    sendConsentToGoogleAnalytics();
  };

  // Função para enviar o consentimento para o Google Analytics
  const sendConsentToGoogleAnalytics = () => {
    // Verifica se o objeto 'gtag' está disponível no window, e envia evento
    if (window.gtag) {
      window.gtag('event', 'cookie_consent', {
        'event_category': 'LGPD',
        'event_label': 'Consentido',
        'value': 1,
      });
    }
  };

  return !isAccepted ? (
    <Banner>
      <Message>Nós usamos cookies para melhorar sua experiência. Você aceita?</Message>
      <Button onClick={handleAccept}>
        Aceitar
      </Button>
    </Banner>
  ) : null;
};

export default CookieConsent;
