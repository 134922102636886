import styled from 'styled-components';

export const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 21rem;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  font-family: var(--font-Roboto);
  transition: transform 0.3s ease;
  flex-direction: column;
  position: relative;

  &:hover {
    transform: scale(1.03);
  }
`;

export const CardImage = styled.div`
  width: 100%;
  height: 16rem;
  overflow: hidden; /* Controla o tamanho da imagem e esconde o zoom extra */
  position: relative; /* Importante para posicionar o HighlightBanner sobre a imagem */
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem mantenha a proporção correta */
    transition: transform 0.3s ease; /* Adiciona a transição suave */
  }

  img:hover {
    transform: scale(1.2); /* Aplica o zoom de 20% ao passar o mouse */
  }

  @media screen and (max-width: 1200px) {
    height: 18rem;
  }
`;

export const CardBody = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h5`
  font-size: 1.20rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  img {
    width: 28px;
    height: auto;
  }
`;

export const CardText = styled.div`
  display: flex;
  font-size: 1.2em;
  gap: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #808080;
  margin: 1.2rem 0;

  svg {
    color: red;
  }
  
  p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
    
`;

export const CardPrice = styled.div`
  font-size: 1.3em;
  display: flex;
  gap: 0.5rem;

  p {
    color: #808080;
    margin-bottom: 1rem;
  }
`

export const CardButton = styled.button`
  background-color: #433FBF;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
  

  &:hover {
    background-color: #d7231a;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`;


export const HighlightBanner = styled.div`
  font-family: var(--font-Roboto);
  position: absolute; /* Permite posicionar o banner sobre a imagem */
  top: 0; /* Coloca o banner no topo da imagem */
  left: 0; /* Ajusta o banner para começar à esquerda */
  width: 100%; /* Faz com que o banner ocupe toda a largura da imagem */
  background-color: rgba(255, 0, 0, 0.7); /* Fundo vermelho semi-transparente */
  color: white;
  padding: 5px;
  font-size: 1.2rem;
  text-align: center;
  z-index: 1;  /* Garante que a faixa fique acima da imagem */
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);  /* Sombra para dar destaque */
`;