import styled from "styled-components";
import { Link } from "react-router-dom";

export const CSidebar = styled.div`
  display: flex;
  width: 100%;
  background-color: black;
  padding: 0.8rem;
  justify-content: space-between;
  align-items: center;
  z-index: 20; 
  position: relative;
  

  h1 {
    color: white;
  }

  @media screen and (max-width: 768px) {
    img {
      max-width: 10rem;
    }   
  }
`;

export const CaseWindow = styled.div<{$toggleSideBar: boolean}>`
  width: 100vw;
  height: ${({ $toggleSideBar }) => ($toggleSideBar ? "100vh" : "0")};
  position: fixed;
  top: ${({ $toggleSideBar }) => ($toggleSideBar ? "0" : "-100%")}; 
  left: 0;
  transition: height 0.9s ease-in-out, top 0.9s ease-in-out; 
  -webkit-transition: height 0.9s ease-in-out, top 0.9s ease-in-out;
  background-color: transparent;
  overflow: hidden; 
`;

export const Overlay = styled.div<{$toggleSideBar: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ $toggleSideBar }) => ($toggleSideBar ? 'block' : 'none')};
  z-index: 5;
`;

export const NavIconOpen = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 2rem;
  outline: none;
  margin-right: 0.2rem;
  

  svg {
    color: #FFFFFF;

    &:active {
      color: green;
      background-color: lightgrey;
      border-radius: 5px;
      transition: 0.3s ease;
      -webkit-transition: 0.3s ease;
    }
  }

  &:active {
    text-decoration: none;
  }
`;

export const CSidebarHeader = styled.div`
  width: 100%;
  padding: 0.8rem;
  background-color: #000;
  color: #fff;
  

  h2 {
    font-family: var(--font-Roboto);
    font-weight: bold;
    font-size: 1.2em;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
  }

  svg {
    color: #fff;
  }


  @media screen and (max-width: 768px) {
    img {
      max-width: 10rem;
    }   
  }
`;


export const SidebarNav = styled.nav<{$toggleSideBar: boolean}>`
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.9s ease-in-out;
  -webkit-transition: transform 0.9s ease-in-out;
  z-index: 10; 
  transform: ${({ $toggleSideBar }) => ($toggleSideBar ? "translateY(0)" : "translateY(-100%)")};
`;

export const CSocialMedia = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #46A64C;
  padding: 0.5rem;

  svg {
    padding: 0.2rem;
    cursor: pointer;
    width: auto;
    height: 2.5rem;
    color: white;
  }
`;

export const SideBarWrap = styled.div`
  width: 100%;
  
`;

export const CClosed = styled.button`
  width: 100%;
  background-color: #000;
  border: none;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  color: #fff;

  svg {
    font-size: 1.4em;
  }

`
