import styled from 'styled-components';

export const SLocation = styled.section`
  display: flex;
  padding: 2rem;
  font-family: var(--font-Roboto);
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const CMaps = styled.div`
  iframe {
    width: 600px;
    height: 450px; 
  }

  @media screen and (max-width: 768px) {
    iframe {
      width: 21rem;
      height: 17rem; 
    }
  }
`

export const CAddress = styled.div`

`

export const CTitle = styled.div`
  margin-bottom: 2rem;

  h1{
    font-size: 3.4em;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  span {
    color: #c1c1c1;
    font-size: 1.2rem;
  }
`

export const CContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  address {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
      color: red;
    }
  }

  span {
    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      svg{
        color: red;
      }
    }
  }

  a {
    text-decoration: none;
    color: #000;
  }

  button {
    padding: 0.7rem 2rem;
    background-color: #25d366;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: #128c7e;
    }
  }

  @media screen and (max-width: 768px) {
    address {
      text-align: center;
    }
  }
`

export const ScheduleTable  = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  p {
    display: table-row;
    border: 1px solid #ddd;
    text-align: center;
  }

  span {
    display: table-cell;
    padding: 0.5rem;
    text-align: center;
  }

  p:first-child {
    font-weight: bold;
  }

  p span {
    border-left: 1px solid #ddd;
  }

  @media (max-width: 768px) {

    span {
      padding: 0.2rem;
    }
  }
`