import { useState } from 'react';
import { 
  TabContainer,
  TabButton, 
  TabContent, 
  TabHeader, 
  CustomCarousel, 
  ContainerServiceContent, 
  ContentServicesOne,
  HeaderContentServiceOne,
  ContentContact,
  ContentServiceAndContact,
  TabContentInner,
  HeaderService,
  ContentServicesTwo,
  HeaderContentServiceTwo,
  ContentServiceAndContactTwo,
  ContentContactTwo
} from './BundledServices.styles';
import { Carousel } from 'react-bootstrap';

import { BsWhatsapp, BsInstagram, BsTelephoneFill } from "react-icons/bs";

import w1 from '../../../../assets/img/slidesImg/lavaJatoOne.png';

import m1 from '../../../../assets/img/slidesImg/mecanicaOne.png';

import yourLogo from '../../../../assets/img/logo/Yourlogo.png';

import logoFrickWash from '../../../../assets/img/logoServices/LavaJato.png'

interface TabProps {
  label: string;
  content: React.ReactNode;
}

const BundledServices = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [fade, setFade] = useState(true); // Estado para controlar o fade


  // Função para redirecionar para o WhatsApp 
  const enviarMensagemWhatsAppWash = (mensagem: string) => {
    const numero = '+5582999158373'; // Número de WhatsApp
    const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
  };
 
  const enviarMensagemWhatsAppMecanica = (mensagem: string) => {
    const numero = '+5582988473752'; // Número de WhatsApp
    const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensagem)}`;
    window.open(url, '_blank'); // Abre o WhatsApp em uma nova aba
  };

  const tabData: TabProps[] = [
    { 
      label: 'Motowash', 
      content: (
        <ContainerServiceContent id='services'>
          <CustomCarousel className='carousel slide carousel-fade'>
            <Carousel.Item>
              <img src={w1} alt="Imagem lavajato Garagem 57" loading='lazy'/>
            </Carousel.Item>
          </CustomCarousel>
          <ContentServicesOne>
            <HeaderContentServiceOne>
              <div>
                <h3>Jet Frick Motowash</h3>
                <span>Empresa especializada em detalhamento de motocicletas com serviços de excelência.</span>
              </div>
              <span>
                <img src={logoFrickWash} alt="Logo Lavaja frick MotoWash" loading='lazy'/>
              </span>
            </HeaderContentServiceOne>
            <ContentServiceAndContact>
              <ul>
                <li>Polimetos</li>
                <li>Pintura</li>
                <li>Escapamento</li>
                <li>Vitrificação</li>
                <li>Lavagem simples</li>
                <li>Lavagem detalhadas</li>
              </ul>
              <ul>
                <li>Polimetos</li>
                <li>Pintura</li>
                <li>Escapamento</li>
                <li>Vitrificação</li>
                <li>Lavagem simples</li>
                <li>Lavagem detalhadas</li>
              </ul>
              <ContentContact>
                <p>Contato:</p>
                <a href="tel:+5582999158373"><BsTelephoneFill />(82) 99915-8373</a>
                <div>
                  <button 
                    type='button' 
                    className='whatsapp-button'  
                    onClick={() => enviarMensagemWhatsAppWash('Quero agendar um horário')}>
                    <BsWhatsapp />
                  </button>
                  <button type='button' className='instagram-button' ><BsInstagram /></button>
                </div>
                <button 
                  type='button' 
                  className='agendamento-button' 
                  onClick={() => enviarMensagemWhatsAppWash('Quero agendar um horário')}
                >
                  Agendar horário
                </button>
              </ContentContact>
            </ContentServiceAndContact> 
          </ContentServicesOne>
        </ContainerServiceContent>
      )
    },
    { 
      label: 'Mecânica', 
      content: (
        <ContainerServiceContent>
          <CustomCarousel className='carousel slide carousel-fade'>
            <Carousel.Item>
              <img src={m1} alt="Imagens mecânica Garagem 57"  loading='lazy'/>
            </Carousel.Item>
          </CustomCarousel>
          <ContentServicesTwo>
            <HeaderContentServiceTwo>
              <div>
                <h3>Luciano Mecânico</h3>
                <span>Manutenção e reparos com profissionais experientes.</span>
              </div>
              <span>
                <img src={yourLogo} alt="sua logo" loading='lazy'/>
              </span>
            </HeaderContentServiceTwo>
            <ContentServiceAndContactTwo>
              <ul>
                <li>Reparos de motor</li>
                <li>Troca de óleo</li>
                <li>Serviços de suspensão</li>
                <li>Alinhamento e balanceamento</li>
              </ul>
              <ul>
                <li>Revisão completa</li>
                <li>Troca de freios</li>
                <li>Diagnóstico eletrônico</li>
                <li>Outros serviços especializados</li>
              </ul>
              <ContentContactTwo>
                <p>Contato:</p>
                <a href="tel:+5582988473752"><BsTelephoneFill />(82) 98847-3752</a>
                <div>
                  <button 
                    type='button' 
                    className='whatsapp-button' 
                    onClick={() => enviarMensagemWhatsAppMecanica('Quero agendar um horário')}
                  >
                    <BsWhatsapp />
                  </button>
                  <button type='button' className='instagram-button' >
                    <BsInstagram />
                  </button>
                </div>
                <button 
                type='button' 
                className='agendamento-button'
                onClick={() => enviarMensagemWhatsAppMecanica('Quero agendar um horário')}
                >
                  Agendar horário
                </button>
              </ContentContactTwo>
            </ContentServiceAndContactTwo> 
          </ContentServicesTwo>
        </ContainerServiceContent>
      )
    }
  ];

  const handleTabChange = (index: number) => {
    setFade(false); // Começa com fade-out
    setTimeout(() => {
      setActiveTab(index);
      setFade(true); // Depois de um tempo, inicia o fade-in
    }, 500); // O tempo deve ser igual ao tempo de transição
  };

  return (
    <TabContainer id='services'>
      <HeaderService>
        <h2>Confira Nossos Serviços</h2>
      </HeaderService>
    <TabHeader>
      {tabData.map((tab, index) => (
        <TabButton
          key={index}
          active={activeTab === index}
          onClick={() => handleTabChange(index)}
          tabIndex={index}
        >
          {tab.label}
        </TabButton>
      ))}
    </TabHeader>
    <TabContentInner>
      <TabContent className={fade ? 'fade-in' : 'fade-out'} isActive={true}>
        {tabData[activeTab].content}
      </TabContent>
    </TabContentInner>
  </TabContainer>
  );
};

export default BundledServices;